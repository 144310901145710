<template>
    <v-card data-cy="card-devedores" class="pa-3" :class="{'ma-3': showInModal}">
        <v-container>
            <v-row>
                <v-col>
                    <p class="title">
                        <v-icon class="ma-2">mdi-information-outline</v-icon
                        >Codevedores cadastrados:
                        <span
                            :class="
                                titulo.nu_devedores_cadastrados ===
                                titulo.nu_quantidade_devedores
                                    ? 'text-green ml-2'
                                    : 'text-orange ml-2'
                            "
                            >{{ titulo.nu_devedores_cadastrados - 1 }}/{{
                                titulo.nu_quantidade_devedores - 1
                            }}</span
                        >
                    </p>
                </v-col>
                <v-col class="d-flex justify-end">
                    <v-btn
                        v-if="
                            titulo.nu_devedores_cadastrados !==
                            titulo.nu_quantidade_devedores && 
                            isUpdateValid &&
                            verificaPermissaoCodevedores
                        "
                        data-cy="btn-adicionar-devedor"
                        @click="addDevedor()"
                    >
                        Adicionar
                    </v-btn>
                </v-col>
            </v-row>
            <div v-for="(coodevedor, index) in coodevedores" :key="index">
                <v-row v-if="!coodevedor.fl_devedor_principal">
                    <v-col class="py-0">
                        <v-text-field
                            label="Nome do Devedor"
                            outlined
                            maxlength="45"
                            readonly
                            dense
                            v-model="coodevedor.nm_devedor"
                        />
                    </v-col>
                    <v-col class="py-0">
                        <v-text-field
                            outlined
                            dense
                            readonly
                            v-model="coodevedor.nu_identificacao_devedor"
                            v-mask="['###.###.###-##', 'XX.XXX.XXX/XXXX-##']"
                            label="CPF/CNPJ"
                        />
                    </v-col>
                    <v-col class="py-0">
                        <v-btn
                            v-if="!coodevedor.fl_devedor_principal &&
                            isUpdateValid &&
                            verificaPermissaoCodevedores"
                            icon
                            :data-cy="'btn-excluir-devedor-' + index"
                            @click="excluirDevedor(coodevedor)"
                        >
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-btn icon @click="visualizar(coodevedor)">
                            <v-icon>mdi-eye</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-container>
        <v-dialog v-model="dialogEdit" max-width="800">
            <v-card>
                <v-card-title class="text-h5 primary text-white">
                    <span class="headline">{{ tp_modal }} devedor</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row class="mt-5">
                            <v-col>
                                <v-text-field
                                    label="Nome do Devedor"
                                    data-cy="input-nome-devedor"
                                    maxlength="45"
                                    counter="45"
                                    :rules="rules"
                                    :readonly="tp_modal === 'Visualizar'"
                                    outlined
                                    dense
                                    v-model="coodevedorEdit.nm_devedor"
                                />
                            </v-col>
                            <v-col>
                                <v-text-field
                                    outlined
                                    data-cy="input-cpf-cnpj-devedor"
                                    dense
                                    :readonly="tp_modal === 'Visualizar'"
                                    v-model="
                                        coodevedorEdit.nu_identificacao_devedor
                                    "
                                    v-mask="[
                                        '###.###.###-##',
                                        'XX.XXX.XXX/XXXX-##',
                                    ]"
                                    label="CPF/CNPJ"
                                />
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col>
                                <v-text-field
                                    data-cy="input-endereco-devedor"
                                    label="Endereço"
                                    :readonly="tp_modal === 'Visualizar'"
                                    outlined
                                    dense
                                    v-model="coodevedorEdit.ds_endereco_devedor"
                                />
                            </v-col>
                            <v-col>
                                <v-text-field
                                    label="Bairro"
                                    :readonly="tp_modal === 'Visualizar'"
                                    data-cy="input-bairro-devedor"
                                    outlined
                                    dense
                                    v-model="coodevedorEdit.ds_bairro_devedor"
                                />
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col>
                                <v-text-field
                                    data-cy="input-cep-devedor"
                                    label="CEP"
                                    :readonly="tp_modal === 'Visualizar'"
                                    outlined
                                    dense
                                    v-model="coodevedorEdit.cd_cep_devedor"
                                />
                            </v-col>
                            <v-col>
                                <v-text-field
                                    data-cy="input-uf-devedor"
                                    label="UF"
                                    outlined
                                    :readonly="tp_modal === 'Visualizar'"
                                    maxlength="2"
                                    dense
                                    v-model="coodevedorEdit.cd_uf_devedor"
                                    @input="
                                        (input) =>
                                            (coodevedorEdit.cd_uf_devedor =
                                                input.toUpperCase())
                                    "
                                />
                            </v-col>
                            <v-col>
                                <v-text-field
                                    data-cy="input-cidade-devedor"
                                    label="Cidade"
                                    outlined
                                    dense
                                    :readonly="tp_modal === 'Visualizar'"
                                    v-model="coodevedorEdit.nm_cidade_devedor"
                                />
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col>
                                <v-text-field
                                    data-cy="input-email-devedor"
                                    label="E-mail"
                                    outlined
                                    :readonly="tp_modal === 'Visualizar'"
                                    dense
                                    v-model="coodevedorEdit.ds_email_devedor"
                                />
                            </v-col>
                            <v-col>
                                <v-text-field
                                    data-cy="input-telefone-devedor"
                                    label="Telefone"
                                    outlined
                                    :readonly="tp_modal === 'Visualizar'"
                                    dense
                                    v-model="coodevedorEdit.nu_telefone_devedor"
                                />
                            </v-col>
                        </v-row>
                        <span
                            v-for="(feedback, index) in feedbacks"
                            :key="index"
                        >
                            <v-alert
                                :value="true"
                                type="error"
                                transition="scale-transition"
                                dismissible
                            >
                                {{ feedback }}
                            </v-alert>
                        </span>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogEdit = false"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn
                        v-if="tp_modal === 'Adicionar'"
                        :disabled="!isFormValid"
                        color="blue darken-1"
                        text
                        data-cy="btn-adicionar-confirmar"
                        @click="submitForm()"
                    >
                        Adicionar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500">
            <v-card>
                <v-card-title class="red text-white">
                    <span class="headline">Confirmação</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <p>
                                    Tem certeza que deseja excluir o devedor
                                    {{ devedorDelete.nm_devedor }}?
                                </p>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialogDelete = false"> Cancelar </v-btn>
                    <v-btn data-cy="btn-excluir-confirmar" color="red darken-1" text @click="deleteDevedor()">
                        Excluir
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>
<script>
import TituloService from '../../services/TituloService';
import Vue from 'vue';
export default {
    name: 'CardCoodevedores',
    props: {
        titulo: {
            type: Object,
            required: true,
        },
        showInModal: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            rules: [v => v.length <= 45 || 'Máximo 45 caracteres'],
            tituloService: new TituloService(Vue.http, this.$store),
            tp_modal: '',
            coodevedores: [],
            coodevedorEdit: {},
            dialogEdit: false,
            dialogDelete: false,
            edit: false,
            devedorDelete: {},
            novoDevedor: {
                nm_devedor: '',
                ds_endereco_devedor: '',
                ds_bairro_devedor: '',
                cd_cep_devedor: '',
                cd_uf_devedor: '',
                nm_cidade_devedor: '',
                tp_identificacao_devedor: '',
                nu_identificacao_devedor: '',
                ds_email_devedor: '',
                nu_telefone_devedor: '',
                id_titulo: this.titulo.id_titulo,
                cd_apresentante: this.titulo.cd_apresentante,
            },
            feedbacks: [],
        };
    },
    created() {
        this.getCoodevedores();
    },
    computed: {
        isUpdateValid(){
            return this.titulo.cd_status === 220 && this.titulo.st_envio != 1
        },
        verificaPermissaoCodevedores() {
            if (this.$store.getters.isBasicUser 
                || this.$store.getters.isAdmin
                || this.$store.getters.isSuperUser) {
                return true;
            }
            return false;
        },
        isFormValid() {
            return (
                this.coodevedorEdit.nm_devedor !== '' &&
                this.coodevedorEdit.ds_endereco_devedor !== '' &&
                this.coodevedorEdit.ds_bairro_devedor !== '' &&
                this.coodevedorEdit.cd_cep_devedor !== '' &&
                this.coodevedorEdit.cd_uf_devedor !== '' &&
                this.coodevedorEdit.nm_cidade_devedor !== '' &&                
                this.coodevedorEdit.nu_identificacao_devedor !== '' &&                
                this.coodevedorEdit.id_titulo !== '' &&
                this.coodevedorEdit.cd_apresentante !== ''
            );
        },
    },
    methods: {
        getCoodevedores() {
            console.log(this.titulo);
            this.tituloService.getCoodevedores(this.titulo).then((response) => {
                console.log(response);
                this.coodevedores = response;
            });
        },
        excluirDevedor(coodevedor) {
            this.devedorDelete = coodevedor;
            this.dialogDelete = true;
        },
        visualizar(coodevedor) {
            this.coodevedorEdit = coodevedor;
            this.tp_modal = 'Visualizar';
            this.dialogEdit = true;
            console.log(coodevedor);
        },
        addDevedor() {
            this.tp_modal = 'Adicionar';
            this.coodevedorEdit = this.novoDevedor;
            this.dialogEdit = true;
        },
        deleteDevedor() {
            this.tituloService
                .deleteCoodevedor(this.devedorDelete)
                .then(() => {
                    this.getCoodevedores();
                    this.dialogDelete = false;
                    this.$emit('atualizarTitulo');
                });
        },
        submitForm() {
            if (this.tp_modal == 'Adicionar') {
                this.coodevedorEdit.nu_identificacao_devedor =
                    this.coodevedorEdit.nu_identificacao_devedor.replace(
                        /\D/g,
                        ''
                    );
                this.coodevedorEdit.tp_identificacao_devedor =
                    this.coodevedorEdit.nu_identificacao_devedor.length == 14
                        ? 1
                        : 2;
                this.tituloService
                    .addCoodevedor(this.coodevedorEdit)
                    .then(() => {
                        this.getCoodevedores();
                        this.$emit('atualizarTitulo');
                        this.feedbacks = [];
                        this.dialogEdit = false;
                    })
                    .catch((error) => {
                        this.feedbacks = error.data;
                    });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.text-green {
    color: green;
}
.text-orange {
    color: orange;
}
.text-white {
    color: white;
}
</style>