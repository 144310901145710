var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "pa-3",
      class: { "ma-3": _vm.showInModal },
      attrs: { "data-cy": "card-devedores" }
    },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c(
                  "p",
                  { staticClass: "title" },
                  [
                    _c("v-icon", { staticClass: "ma-2" }, [
                      _vm._v("mdi-information-outline")
                    ]),
                    _vm._v("Codevedores cadastrados: "),
                    _c(
                      "span",
                      {
                        class:
                          _vm.titulo.nu_devedores_cadastrados ===
                          _vm.titulo.nu_quantidade_devedores
                            ? "text-green ml-2"
                            : "text-orange ml-2"
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.titulo.nu_devedores_cadastrados - 1) +
                            "/" +
                            _vm._s(_vm.titulo.nu_quantidade_devedores - 1)
                        )
                      ]
                    )
                  ],
                  1
                )
              ]),
              _c(
                "v-col",
                { staticClass: "d-flex justify-end" },
                [
                  _vm.titulo.nu_devedores_cadastrados !==
                    _vm.titulo.nu_quantidade_devedores &&
                  _vm.isUpdateValid &&
                  _vm.verificaPermissaoCodevedores
                    ? _c(
                        "v-btn",
                        {
                          attrs: { "data-cy": "btn-adicionar-devedor" },
                          on: {
                            click: function($event) {
                              return _vm.addDevedor()
                            }
                          }
                        },
                        [_vm._v(" Adicionar ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._l(_vm.coodevedores, function(coodevedor, index) {
            return _c(
              "div",
              { key: index },
              [
                !coodevedor.fl_devedor_principal
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { staticClass: "py-0" },
                          [
                            _c("v-text-field", {
                              attrs: {
                                label: "Nome do Devedor",
                                outlined: "",
                                maxlength: "45",
                                readonly: "",
                                dense: ""
                              },
                              model: {
                                value: coodevedor.nm_devedor,
                                callback: function($$v) {
                                  _vm.$set(coodevedor, "nm_devedor", $$v)
                                },
                                expression: "coodevedor.nm_devedor"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticClass: "py-0" },
                          [
                            _c("v-text-field", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: [
                                    "###.###.###-##",
                                    "XX.XXX.XXX/XXXX-##"
                                  ],
                                  expression:
                                    "['###.###.###-##', 'XX.XXX.XXX/XXXX-##']"
                                }
                              ],
                              attrs: {
                                outlined: "",
                                dense: "",
                                readonly: "",
                                label: "CPF/CNPJ"
                              },
                              model: {
                                value: coodevedor.nu_identificacao_devedor,
                                callback: function($$v) {
                                  _vm.$set(
                                    coodevedor,
                                    "nu_identificacao_devedor",
                                    $$v
                                  )
                                },
                                expression:
                                  "coodevedor.nu_identificacao_devedor"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticClass: "py-0" },
                          [
                            !coodevedor.fl_devedor_principal &&
                            _vm.isUpdateValid &&
                            _vm.verificaPermissaoCodevedores
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      icon: "",
                                      "data-cy": "btn-excluir-devedor-" + index
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.excluirDevedor(coodevedor)
                                      }
                                    }
                                  },
                                  [_c("v-icon", [_vm._v("mdi-delete")])],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.visualizar(coodevedor)
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("mdi-eye")])],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          })
        ],
        2
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800" },
          model: {
            value: _vm.dialogEdit,
            callback: function($$v) {
              _vm.dialogEdit = $$v
            },
            expression: "dialogEdit"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h5 primary text-white" },
                [
                  _c("span", { staticClass: "headline" }, [
                    _vm._v(_vm._s(_vm.tp_modal) + " devedor")
                  ])
                ]
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        { staticClass: "mt-5" },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Nome do Devedor",
                                  "data-cy": "input-nome-devedor",
                                  maxlength: "45",
                                  counter: "45",
                                  rules: _vm.rules,
                                  readonly: _vm.tp_modal === "Visualizar",
                                  outlined: "",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.coodevedorEdit.nm_devedor,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.coodevedorEdit,
                                      "nm_devedor",
                                      $$v
                                    )
                                  },
                                  expression: "coodevedorEdit.nm_devedor"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: [
                                      "###.###.###-##",
                                      "XX.XXX.XXX/XXXX-##"
                                    ],
                                    expression:
                                      "[\n                                    '###.###.###-##',\n                                    'XX.XXX.XXX/XXXX-##',\n                                ]"
                                  }
                                ],
                                attrs: {
                                  outlined: "",
                                  "data-cy": "input-cpf-cnpj-devedor",
                                  dense: "",
                                  readonly: _vm.tp_modal === "Visualizar",
                                  label: "CPF/CNPJ"
                                },
                                model: {
                                  value:
                                    _vm.coodevedorEdit.nu_identificacao_devedor,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.coodevedorEdit,
                                      "nu_identificacao_devedor",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "\n                                    coodevedorEdit.nu_identificacao_devedor\n                                "
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-0" },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "data-cy": "input-endereco-devedor",
                                  label: "Endereço",
                                  readonly: _vm.tp_modal === "Visualizar",
                                  outlined: "",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.coodevedorEdit.ds_endereco_devedor,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.coodevedorEdit,
                                      "ds_endereco_devedor",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "coodevedorEdit.ds_endereco_devedor"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Bairro",
                                  readonly: _vm.tp_modal === "Visualizar",
                                  "data-cy": "input-bairro-devedor",
                                  outlined: "",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.coodevedorEdit.ds_bairro_devedor,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.coodevedorEdit,
                                      "ds_bairro_devedor",
                                      $$v
                                    )
                                  },
                                  expression: "coodevedorEdit.ds_bairro_devedor"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-0" },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "data-cy": "input-cep-devedor",
                                  label: "CEP",
                                  readonly: _vm.tp_modal === "Visualizar",
                                  outlined: "",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.coodevedorEdit.cd_cep_devedor,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.coodevedorEdit,
                                      "cd_cep_devedor",
                                      $$v
                                    )
                                  },
                                  expression: "coodevedorEdit.cd_cep_devedor"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "data-cy": "input-uf-devedor",
                                  label: "UF",
                                  outlined: "",
                                  readonly: _vm.tp_modal === "Visualizar",
                                  maxlength: "2",
                                  dense: ""
                                },
                                on: {
                                  input: input =>
                                    (_vm.coodevedorEdit.cd_uf_devedor = input.toUpperCase())
                                },
                                model: {
                                  value: _vm.coodevedorEdit.cd_uf_devedor,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.coodevedorEdit,
                                      "cd_uf_devedor",
                                      $$v
                                    )
                                  },
                                  expression: "coodevedorEdit.cd_uf_devedor"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "data-cy": "input-cidade-devedor",
                                  label: "Cidade",
                                  outlined: "",
                                  dense: "",
                                  readonly: _vm.tp_modal === "Visualizar"
                                },
                                model: {
                                  value: _vm.coodevedorEdit.nm_cidade_devedor,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.coodevedorEdit,
                                      "nm_cidade_devedor",
                                      $$v
                                    )
                                  },
                                  expression: "coodevedorEdit.nm_cidade_devedor"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-0" },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "data-cy": "input-email-devedor",
                                  label: "E-mail",
                                  outlined: "",
                                  readonly: _vm.tp_modal === "Visualizar",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.coodevedorEdit.ds_email_devedor,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.coodevedorEdit,
                                      "ds_email_devedor",
                                      $$v
                                    )
                                  },
                                  expression: "coodevedorEdit.ds_email_devedor"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "data-cy": "input-telefone-devedor",
                                  label: "Telefone",
                                  outlined: "",
                                  readonly: _vm.tp_modal === "Visualizar",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.coodevedorEdit.nu_telefone_devedor,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.coodevedorEdit,
                                      "nu_telefone_devedor",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "coodevedorEdit.nu_telefone_devedor"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._l(_vm.feedbacks, function(feedback, index) {
                        return _c(
                          "span",
                          { key: index },
                          [
                            _c(
                              "v-alert",
                              {
                                attrs: {
                                  value: true,
                                  type: "error",
                                  transition: "scale-transition",
                                  dismissible: ""
                                }
                              },
                              [_vm._v(" " + _vm._s(feedback) + " ")]
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialogEdit = false
                        }
                      }
                    },
                    [_vm._v(" Cancelar ")]
                  ),
                  _vm.tp_modal === "Adicionar"
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: !_vm.isFormValid,
                            color: "blue darken-1",
                            text: "",
                            "data-cy": "btn-adicionar-confirmar"
                          },
                          on: {
                            click: function($event) {
                              return _vm.submitForm()
                            }
                          }
                        },
                        [_vm._v(" Adicionar ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.dialogDelete,
            callback: function($$v) {
              _vm.dialogDelete = $$v
            },
            expression: "dialogDelete"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "red text-white" }, [
                _c("span", { staticClass: "headline" }, [_vm._v("Confirmação")])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c("p", [
                              _vm._v(
                                " Tem certeza que deseja excluir o devedor " +
                                  _vm._s(_vm.devedorDelete.nm_devedor) +
                                  "? "
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialogDelete = false
                        }
                      }
                    },
                    [_vm._v(" Cancelar ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "data-cy": "btn-excluir-confirmar",
                        color: "red darken-1",
                        text: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.deleteDevedor()
                        }
                      }
                    },
                    [_vm._v(" Excluir ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }